import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../components/Layout';
import * as Api from '../apis';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import useActiveSession from '../hooks/useActiveSession';
import { getUrlParams } from '../helpers/location';
import { navigate } from 'gatsby';

const useStyles = makeStyles(theme => ({
  result: {
    padding: '0px 15px',
  },
  textBlue: {
    color: 'blue',
  },
  textRed: {
    color: 'red',
  },
  phoneButton: {
    width: '80%',
    padding: '20px',
    fontSize: '18px',
  },
  buttonContainer: {
    position: 'fixed',
    bottom: '30px',
    width: '100%',
    // marginTop: '30px'
  },
  redoButtonContainer: {
    marginTop: 30,
  },
  redoButton: {
    padding: '10px 15px',
    fontSize: '16px',
    background: 'green',
    '&:hover': {
      background: 'green',
    },
  },
}));

const RegisterPage = ({ location }) => {
  const classes = useStyles();
  const [result, setResult] = useState(null);
  const query = getUrlParams(location.search);
  const questionnaireId = query.questionnaireId;

  function fetchResultData() {
    Api.getResult(questionnaireId).then(data => {
      setResult(data);
    });
  }

  function handleRedoClick() {
    navigate(`/questionnaire?questionnaireId=${questionnaireId}`);
  }

  useActiveSession(() => {
    fetchResultData();
  });

  return (
    <Layout>
      <Box>
        <h2 align='center'>สรุปผล</h2>
        {result && (
          <>
            {/* <h3 align="center">ความเสี่ยง {result.total_score} %</h3> */}
            <h3 align='center'>ผลการประเมิน</h3>
            {result.interprets.map(interpret => (
              <h3
                align='center'
                className={`${classes.result} ${
                  interpret.score >= 50 ? classes.textRed : classes.textBlue
                }`}
              >
                {interpret.interpret}
              </h3>
            ))}
          </>
        )}
        <Box
          justifyContent='center'
          display='flex'
          className={classes.redoButtonContainer}
        >
          <Button
            color='secondary'
            variant='contained'
            className={classes.redoButton}
            onClick={handleRedoClick}
          >
            ทำแบบประเมินใหม่
          </Button>
        </Box>
      </Box>
      <Box
        justifyContent='center'
        display='flex'
        className={classes.buttonContainer}
      >
        <Button
          color='primary'
          variant='contained'
          className={classes.phoneButton}
          onClick={() => window.open('tel:1422')}
        >
          <LocalPhoneIcon /> &nbsp; โทรออกหน่วยงานที่เกี่ยวข้อง (กรมควบคุมโรค)
        </Button>
      </Box>
    </Layout>
  );
};

export default RegisterPage;
